<template>
  <div
    :class="[
      'form-file-input',
      {
        'form-file-input--errors': hasErrors,
      },
    ]"
  >
    <label class="form-file-input__label-wrap">
      <span class="form-file-input__icon">
        <PaperClipIcon />
      </span>
      <span class="form-file-input__control">
        <span class="form-file-input__label" v-if="label">
          {{ label }}
          <span v-if="optional" class="form-file-input__label-optional">
            ({{ $t("forms.label.optional") }})
          </span>
        </span>
        <span v-else class="form-file-input__label">
          <slot name="label"></slot>
        </span>
        <input
          class="form-file-input__file"
          @input="changeInput"
          @focus="focused = true"
          @blur="focused = false"
          type="file"
          ref="inputFile"
          :accept="accept"
        />
        <span class="form-file-input__field-wrap">
          <span class="form-file-input__field">
            {{ filename }}
            <span v-if="value">({{ formatBytes(value.size) }})</span>
          </span>
        </span>
      </span>
    </label>
    <div class="base-form__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
    <span class="form-file-input__label-tooltip">
      <slot name="tooltip"></slot>
    </span>
  </div>
</template>

<script>
import PaperClipIcon from "@/components/reusables/Icons/PaperClipIcon";

export default {
  name: "FormFileInput",
  components: { PaperClipIcon },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: File,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && !newVal && this.$refs.inputFile) {
        this.$refs.inputFile.value = null;
      }
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    filename() {
      if (!this.value) {
        return "";
      }

      const filename = this.value.name;

      if (filename.length > 30) {
        return `${filename.substring(0, 10)}...${filename.substring(
          filename.length - 10,
          filename.length
        )}`;
      }

      return filename;
    },
  },
  methods: {
    changeInput(event) {
      this.$emit("input", event.target.files[0]);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 KB";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
};
</script>

<style lang="scss" scoped>
.form-file-input {
  position: relative;
  text-align: left;
  margin-bottom: 35px;

  &--errors {
    .form-file-input {
      &__field {
        border-color: var(--form-error-color);
        background-color: var(--form-error-background);
      }

      &__counter {
        color: var(--form-error-color);
      }

      &__icon {
        svg {
          path {
            fill: var(--form-error-color);
          }
        }
      }
    }
  }

  &__label {
    display: block;
    color: var(--form-input-label-color);
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    transition: all 0.3s ease-out;
    white-space: nowrap;
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);

    &-wrap {
      position: relative;
      display: flex;
    }
    &-optional {
      color: var(--nj-gray-4);
    }
    &-tooltip {
      position: absolute;
      right: 0;
      top: 0;

      ::v-deep {
        .tooltip__popup {
          right: 1px;
          top: 28px;
          left: auto;
        }
      }
    }
  }

  &__control {
    position: relative;
    flex-grow: 1;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 15px;
    width: 24px;
    position: absolute;
    left: 5px;
    top: 36px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__file {
    display: block;
    left: 0;
    width: 100%;
    height: 37px;
    position: absolute;
    opacity: 0;
  }

  &__field {
    display: block;
    width: 100%;
    color: var(--form-input-color);
    border: 1px solid var(--form-input-border-color);
    border-radius: 4px;
    line-height: 20px;
    font-size: 16px;
    padding: 8px 10px 8px 35px;
    height: 41px;
  }

  .base-form {
    &__details {
      padding-left: 39px;
    }
  }
}
</style>
