<template>
  <div :class="['form-checkbox', { 'form-checkbox--disabled': disabled }]">
    <div class="form-checkbox__inner-wrap">
      <label class="form-checkbox__inner">
        <input
          class="form-checkbox__input"
          :class="admin ? 'form-checkbox__input--admin' : null"
          type="checkbox"
          :checked="isChecked"
          :value="value"
          @change="updateCheckbox"
          :disabled="disabled"
        />
        <span class="form-checkbox__box"></span>
        <span class="form-checkbox__label" v-if="label">
          {{ label }}
        </span>
      </label>
      <div
        class="form-checkbox__description"
        :class="{ 'form-checkbox__description--disabled': disabled }"
        v-if="hasDescription"
      >
        <slot name="form-checkbox-description"></slot>
      </div>
    </div>
    <div class="base-form__errors form-checkbox__errors" v-if="hasErrors">
      <slot name="errors"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormCheckbox",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number],
    },
    modelValue: {
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue;
    },
  },
  methods: {
    updateCheckbox(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  &--disabled {
    .form-checkbox {
      &__box {
        border-color: var(--nj-gray-2) !important;
        cursor: default;
        background-color: var(--nj-gray-2) !important;
      }

      &__label {
        color: var(--form-disabled-color);
      }

      &__inner {
        cursor: default;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-wrap {
      display: flex;
    }
  }

  &__input {
    display: none;

    &:checked ~ .form-checkbox__box {
      background: var(--nj-green);
      border-color: var(--nj-green);

      &:after {
        opacity: 1;
      }
    }

    &--admin:checked ~ .form-checkbox__box {
      background: var(--nj-pink);
      border-color: var(--nj-pink);
    }
  }

  &__box {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    cursor: pointer;
    transition: 0.1s ease-out all;

    &:after {
      content: "";
      display: block;
      height: 12px;
      width: 7px;
      border-bottom: 2px solid var(--nj-white);
      border-right: 2px solid var(--nj-white);
      transform: rotate(45deg);
      position: absolute;
      left: 4px;
      top: -1px;
      opacity: 0;
    }
  }

  &__label,
  &__description {
    color: var(--form-input-label-color);
    margin-left: 10px;

    &--disabled {
      color: var(--form-disabled-color);
    }
  }

  &__errors {
    margin-top: 10px;
  }
}
</style>
